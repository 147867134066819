import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import toTitleCase from 'titlecase';
import { GoogleMap, useJsApiLoader, Marker, InfoWindowF } from '@react-google-maps/api'
import * as geofire from 'geofire-common';

import firebase from "firebase/app";
import 'firebase/firestore';

const distanceInKm = 200; // km

const EXCLUDE_USER_IDS = [
    '3e6kuriOwMcJcPvrEy73rvyS6St1',
    'WkmDaiwBKDVNuob3jaKxPRBK6jV2',
    'k93dpmta2Gfvnpa91SbJvNEMkZ42',
    '6rRDpayC8WU54A2oc8Hfm0Txxuu2',
    'ugOk1i4HaLQ8N4WeOcc0IgzaNyb2',
    'wt3iQKVhzAZQUxApKiPSV6mwIHw2',
    'icPx9rGC7rWaz8y3XigZKQuofQP2',
    'ybIDVNi24NN5RmchIwsfSJhSaOr1',
];

const defaultCenter = {
    lat: -3.745,
    lng: -38.523,
};

const useStyles = makeStyles({
    container: {
        width: '100vw',
        height: '100vh',
    },
    loading: {
        position: 'fixed',
        top: '50vh',
        left: '50vw'
    },
    marker: {
        width: '1vw',
        height: '1vh',
    },
});

function Leads() {
    let params = useParams();
    let navigate = useNavigate();
    const classes = useStyles();
    const [center, setCenter] = useState(null);
    const [map, setMap] = useState(null);
    const [locations, setLocations] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const onLoad = React.useCallback(function callback(map) {
        navigator.geolocation.getCurrentPosition(
            (location) => {
                const { coords: { latitude, longitude, heading } } = location;
                const _center = { lat: latitude, lng: longitude };
                setCenter(_center);
                const bounds = new window.google.maps.LatLngBounds(_center);
                map.fitBounds(bounds);
                map.setZoom(map.getZoom() - 10);
                setMap(map);
            },
            (err) => {
                setCenter(defaultCenter);
                const bounds = new window.google.maps.LatLngBounds(defaultCenter);
                map.fitBounds(bounds);
                map.setZoom(map.getZoom() - 10);
                setMap(map);
                setTimeout(() => {
                    alert('Error getting your current location. Falling back to default location. ' + `ERROR(${err.code}): ${err.message}`);
                }, 1000);
            },
            {
                timeout: 5000,
                maximumAge: Infinity,
            }
        );
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        if (!center) {
            return;
        }

        const locations = [];

        // Find riders within 5km
        const { lat: latitude, lng: longitude } = center;
        const _center = [latitude, longitude];

        // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
        // a separate query for each pair. There can be up to 9 pairs of bounds
        // depending on overlap, but in most cases there are 4.
        const distanceInMeters = distanceInKm * 1000;
        const bounds = geofire.geohashQueryBounds(_center, distanceInMeters);
        const promises = [];
        for (const b of bounds) {
            const q = firebase.firestore().collection('users_location')
                .orderBy('lastGeohash')
                .startAt(b[0])
                .endAt(b[1]);

            promises.push(q.get());
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {
            const matchingDocs = [];

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const data = doc.data();
                    const { lastLocation: { latitude, longitude } } = data;

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = geofire.distanceBetween([latitude, longitude], _center);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= distanceInMeters) {
                        matchingDocs.push({
                            id: doc.id,
                            ...data,
                            distanceInM,
                        });
                    }
                }
            }

            matchingDocs.sort((doc1, doc2) => doc1.distanceInM - doc2.distanceInM);

            return matchingDocs;
        }).then(async docs => {
            for (let doc of docs) {
                if (EXCLUDE_USER_IDS.includes(doc.id)) {
                    continue; // skip current user
                }
                const { lastLocation: { latitude, longitude }, lastLocationTime } = doc;

                let user = {};
                try {
                    const userDoc = await firebase.firestore().collection('users').doc(doc.id).get();
                    const userData = userDoc.data();

                    try {
                        const { email, } = userData;
                        user = {
                            email,
                            ...user,
                        };
                    } catch (err) {
                        //console.error(err);
                    }

                    try {
                        const { photoURL, } = userData;
                        if (photoURL) {
                            user = {
                                photoURL: {
                                    url: photoURL,
                                    scaledSize: new window.google.maps.Size(32, 32),
                                },
                                ...user,
                            };
                        }
                    } catch (err) {
                        //console.error(err);
                    }

                    try {
                        const { fname, lname, } = userData;
                        user = {
                            fname,
                            lname,
                            ...user,
                        };
                    } catch (err) {
                        //console.error(err);
                    }

                } catch (err) {
                    user = {};
                    //console.warn('User does not exist', doc.id);
                    continue;
                }

                const { seconds, nanoseconds } = lastLocationTime;
                const milliseconds = seconds * 1000 + nanoseconds / 1e6;

                locations.push({
                    id: doc.id,
                    point: {
                        lat: latitude,
                        lng: longitude,
                    },
                    timestamp: moment(milliseconds),
                    ...user,
                });
            }

            setLocations(locations);
        });
    }, [center]);

    return isLoaded ? (
        <GoogleMap
            mapContainerClassName={classes.container}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {locations && locations.map((loc) => (
                <Marker
                    key={`mk_${loc.id}`}
                    //icon={loc.photoURL}
                    position={loc.point}
                    className={classes.marker}
                    onClick={() => setSelectedMarker(loc)} />
            ))}

            {selectedMarker && (
                <InfoWindowF
                    key={`info_${selectedMarker.id}`}
                    position={selectedMarker.point}
                    className='flex flex-col'
                    onCloseClick={() => setSelectedMarker(null)}
                >
                    <div className='text-black leading-6'>
                        <h2 className='text-xl text-bold'>{selectedMarker.fname + ' ' + selectedMarker.lname}</h2>
                        <p className='text-lg'>{selectedMarker.timestamp.fromNow()} ({selectedMarker.timestamp.format('YYYY-MM-DD HH:mm:ss')})</p>
                        <p className='text-lg'>ID: {selectedMarker.id}</p>
                    </div>
                </InfoWindowF>
            )}
        </GoogleMap>
    ) : (
        <div className={classes.loading}>
            <CircularProgress color="secondary" />
        </div>
    )
}

export default React.memo(Leads);
