import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-select-dt';

import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';

const useStyles = makeStyles({
    container: {
        margin: '5px',
        marginTop: '100px',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10px',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 600px)": {
            marginTop: '2rem',
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    lastActiveValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '0'
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',

    },
    avatarValue: {
        "@media (max-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
            marginTop: '0'
        },
    },
    accessBtn: {
        fontWeight: 'bold',
        color: '#042940',
        "@media (min-width: 992px)": {
            width: '21.6%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    }
});

function Prices() {
    const classes = useStyles();

    const [prices, setPrices] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');
    const [pageToken, setPageToken] = useState(null);
    const [table, setTable] = useState(null);

    useEffect(() => {
        setLoading(true);

        (async () => {
            try {
                firebase.firestore().collection("prices_by_region")
                    .orderBy('createdOn', 'desc')
                    .onSnapshot(async snapshot => {
                        let result = [];

                        for (let i in snapshot.docs) {
                            const doc = snapshot.docs[i];
                            const data = doc.data();

                            const regionRef = await data.region.get();
                            const { country, province } = regionRef.data();

                            const countryRef = await country.get();
                            const countryData = countryRef.data();

                            result.push({
                                id: doc.id,
                                ...data,
                                region: {
                                    id: regionRef.id,
                                    ...countryData,
                                    province,
                                },
                            });
                        }

                        setPrices(result);
                    });

                //setPageToken(data.pageToken);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })()
    }, [update]);

    useEffect(() => {
        if (prices && prices.length > 0) {
            if (!table) {
                const _table = new DataTable('#prices-list-table', {
                    responsive: true,
                    select: true,
                });

                _table.on('select', (e, dt, type, indexes) => fillForm(e, dt, type, indexes));
                _table.on('deselect', (e, dt, type, indexes) => fillForm(e, dt, type, indexes));

                setTable(_table);
            }
        }
    }, [prices]);

    const fillForm = (e, dt, type, indexes) => {
        if (type === 'row') {
            const data = dt.rows({ selected: true }).data();
            const nodes = dt.rows({ selected: true }).nodes();

            let item;
            let node;

            if (data.length === 0) {
                item = null;
                node = null;
            } else if (data.length > 0) {
                item = data[data.length - 1];
                node = nodes[nodes.length - 1];
            }

            if (node) {
                const regionId = node.getAttribute('data-region-id');
                document.querySelector(`input[name="region_id"]`).value = regionId;
            }

            const names = ['size', 'title', 'desc', 'region', 'base_rate', 'per_kilometer', 'per_minute', 'order'];
            names.map(name => {
                const inputEle = document.querySelector(`input[name="${name}"]`);
                inputEle.value = item ? item[names.findIndex(value => value === name)].replace('&gt;', '>') : '';
            });
        }
    };

    const createNewPrice = (e) => {
        if (e) {
            e.preventDefault();
        }

        const elements = e.target.elements;

        firebase.firestore().collection('prices_by_region').add({
            region: firebase.firestore().collection('regions').doc(elements.region_id.value),
            size: elements.size.value,
            title: elements.title.value,
            desc: elements.desc.value,
            base_rate: parseFloat(elements.base_rate.value),
            per_kilometer: parseFloat(elements.per_kilometer.value),
            per_minute: parseFloat(elements.per_minute.value),
            order: parseFloat(elements.order.value),
            createdOn: firebase.firestore.Timestamp.now(),
        });

        table && table.rows().deselect();

        return false;
    };

    return <div className={classes.container}>
        <form onSubmit={createNewPrice}>
            <table id='prices-list-table' style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>
                            Size
                        </td>
                        <td>
                            Title
                        </td>
                        <td>
                            Description
                        </td>
                        <td>
                            Region
                        </td>
                        <td>
                            Base Rate
                        </td>
                        <td>
                            Per Kilometer
                        </td>
                        <td>
                            Per Minute
                        </td>
                        <td>
                            Sort Position
                        </td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td>
                            <input name="size" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="title" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="desc" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="region_id" type="text" className="hidden w-full p-2 border-2 border-grey-900" />
                            <input name="region" type="text" className="w-full p-2 border-2 border-grey-900" readonly />
                        </td>
                        <td>
                            <input name="base_rate" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="per_kilometer" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="per_minute" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                        <td>
                            <input name="order" type="text" className="w-full p-2 border-2 border-grey-900" />
                        </td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                            <button type="submit" className="bg-blue-900 text-white py-2 px-6 float-right">Add</button>
                        </td>
                    </tr>
                </thead>
                {loading ?
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                <div className={classes.noRequestFound}>
                                    <CircularProgress color="secondary" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    : prices && prices.length === 0 ?
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    No Prices Found.
                                </td>
                            </tr>
                        </tbody>
                        : <tbody>
                            {
                                prices && prices.map((item, i) => {
                                    return <tr key={i} data-region-id={item.region.id}>
                                        <td>
                                            {item.size}
                                        </td>
                                        <td>
                                            {item.title}
                                        </td>
                                        <td>
                                            {item.desc}
                                        </td>
                                        <td>
                                            {item.region.name}{item?.region.province && ' > '}{item?.region.province}
                                        </td>
                                        <td>
                                            {item.base_rate}
                                        </td>
                                        <td>
                                            {item.per_kilometer}
                                        </td>
                                        <td>
                                            {item.per_minute}
                                        </td>
                                        <td>
                                            {item?.order}
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                }
            </table>
        </form>
    </div>;
}

export default Prices