import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import moment from 'moment';
import toTitleCase from 'titlecase';

import firebase from "firebase/app";
import 'firebase/firestore';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem'
    },
    loading: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    },
    info: {
        textAlign: 'center'
    },
    requestViewContainer: {
        marginLeft: '5%'
    },
    requestView: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'row',
        },
        alignItems: 'center'
    },
    request: {
        marginLeft: 20
    },
    requestBtnContainer: {
        "@media (max-width: 600px)": {
            marginTop: '1rem',
        }
    },
    requestBtn: {
        marginLeft: 20,
        backgroundColor: 'black',
        color: 'white',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '2px 10px'
    }
});

function Driver() {
    let params = useParams();
    let navigate = useNavigate();
    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [cars, setCars] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const userRef = firebase.firestore().collection('users').doc(params.userId);

                const userSnapshot = await userRef.get();

                if (userSnapshot.exists) {
                    const requestSnapshot = await firebase
                        .firestore()
                        .collection('drivers')
                        .doc(params.userId)
                        .get();

                    const requestData = requestSnapshot.data();

                    try {
                        if (requestData.hasOwnProperty('onboarding_driver_license_question')) {
                            const newUrls = [];
                            for (let i = 0; i < requestData.onboarding_driver_license_question.length; i++) {
                                const url = requestData.onboarding_driver_license_question[i];
                                let newUrl = url;
                                if (url.indexOf('drivers') === 0) {
                                    const ref = firebase.storage().ref().child(url);
                                    newUrl = await ref.getDownloadURL();
                                }
                                newUrls.push(newUrl);
                            }
                            requestData.onboarding_driver_license_question = newUrls;
                        }
                    } catch (err) {
                        console.warn('Error getting driver license from drivers collection');
                    }

                    const cars = await firebase.firestore().collection('cars').where('userId', '==', userRef).get();
                    const carsData = [];
                    for (let i in cars.docs) {
                        const car = cars.docs[i];
                        const newDoc = {
                            id: car.id,
                            ...car.data(),
                        };
                        delete newDoc.userId;
                        carsData.push(newDoc);
                    }

                    if (carsData.length > 0) {
                        setCars(carsData);
                    }

                    const _user = {
                        id: userSnapshot.id,
                        ...userSnapshot.data(),
                        ...requestData
                    };
                    console.log('user', _user);
                    setUser(_user);
                }

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })();

        return () => { }
    }, [params.userId, update]);

    const openInNewTab = async (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const driveAccess = async (value) => {
        try {
            setLoading(true);

            if (value === 'Disable') {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        driverStatus: 'Not Allowed'
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        driverStatus: 'Allowed'
                    });
            }

            setUpdate(!update);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const reserveCar = async (value) => {
        try {
            setLoading(true);

            if (value === 'Disable') {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        reserveCarPermission: true
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        reserveCarPermission: false
                    });
            }

            setUpdate(!update);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const verifyDriver = async (value) => {
        try {
            setLoading(true);

            if (value === 'Verify License') {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        licenseStatus: 'Verified'
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        licenseStatus: 'Processing'
                    });
            }


            setUpdate(!update);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const verifyCreditCard = async (value) => {
        try {
            setLoading(true);

            if (value === 'Verify License') {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        insuranceStatus: 'Verified'
                    });
            } else {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(params.userId)
                    .update({
                        insuranceStatus: 'Processing'
                    });
            }


            setUpdate(!update);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const createLastLocationElement = (lastLocation) => {
        const { latitude, longitude } = lastLocation;
        if (latitude && longitude) {
            return <a href={`https://maps.google.com/?ll=${latitude},${longitude}`} target='_blank'>
                <span>{JSON.stringify(lastLocation)}</span>
            </a>
        }
        return <span>{JSON.stringify(lastLocation)}</span>;
    };

    const createAddressElement = (address) => {
        const { url } = address;
        if (url) {
            return <a href={url} target='_blank'>
                <span>{JSON.stringify(address)}</span>
            </a>
        }
        return <span>{JSON.stringify(address)}</span>;
    };

    if (loading) {
        return <div className={classes.loading}>
            <CircularProgress color="secondary" />
        </div>
    }

    return (
        <div className={classes.container}>
            <KeyboardBackspaceIcon onClick={() => navigate(-1)} fontSize='large' sx={{ marginLeft: '4%', marginTop: '2rem' }} />
            <Avatar
                alt='logo'
                src={user?.photoURL}
                sx={{ width: 125, height: 125, marginLeft: 'auto', marginRight: 'auto' }}
            />
            <div>
                <h3 className={classes.info}>{user?.fname} {user?.lname}</h3>
                <h4 className={classes.info}>{user?.email}</h4>
                <h4 className={classes.info}>{user?.dob} ({moment(user?.dob, "DD/MM/YYYY").month(0).from(moment().month(0))})</h4>
            </div>
            <div className={classes.requestViewContainer}>
                <h4>
                    Reserve Car
                </h4>
                <div className={classes.requestView}>
                    <h4 className={classes.request}>
                        Access
                    </h4>
                    <button onClick={() => reserveCar(!user?.reserveCarPermission ? 'Disable' : 'Enable')} className={classes.requestBtn}>
                        {!user?.reserveCarPermission ? 'Disable' : 'Enable'}
                    </button>
                </div>
            </div>
            <div className={classes.requestViewContainer}>
                <h4>
                    Driver request
                </h4>
                <div className={classes.requestView}>
                    <h4 className={classes.request}>
                        Access
                    </h4>
                    <button onClick={() => driveAccess(user?.driverStatus === 'Allowed' ? 'Disable' : 'Enable')} className={classes.requestBtn}>
                        {user?.driverStatus === 'Allowed' ? 'Disable' : 'Enable'}
                    </button>
                </div>
            </div>
            <div className={classes.requestViewContainer}>
                <h4>
                    Driver License
                </h4>
                <div className={classes.requestView}>
                    <h4 className={classes.request}>
                        Status
                    </h4>
                    <p className={classes.request}>
                        {user?.licenseStatus}
                    </p>
                    <div className={classes.requestBtnContainer}>
                        {user?.licenseStatus === 'Not Provided'
                            && (!user.hasOwnProperty('onboarding_driver_license_question')
                                || user.onboarding_driver_license_question.length === 0) ?
                            <>
                            </>
                            :
                            <>
                                <button onClick={() => openInNewTab(user?.licenseURL || user?.onboarding_driver_license_question[0])} className={classes.requestBtn}>
                                    View Document
                                </button>
                                <button onClick={() => verifyDriver(user?.licenseStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification')} className={classes.requestBtn}>
                                    {user?.licenseStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification'}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                user && Object.keys(user).map(key => <div className={classes.requestViewContainer}>
                    <h4>
                    </h4>
                    <div className={classes.requestView}>
                        <h4 className={classes.request}>
                            {toTitleCase(key.replace(/_/g, ' '))}
                        </h4>
                        <p className={classes.request}>
                            {
                                user.hasOwnProperty(key) && user[key]
                                    ? Array.isArray(user[key])
                                        ? user[key].map(value => <>
                                            {value && (value.indexOf('http://') !== -1 || value.indexOf('https://') !== -1)
                                                ? <a href={value} target='_blank'>
                                                    <span>{value}</span>
                                                </a>
                                                : <span>{value}</span>}
                                        </>)
                                        : typeof user[key] === 'string'
                                            ? user[key].hasOwnProperty('indexOf') && (user[key].indexOf('http://') !== -1 || user[key].indexOf('https://') !== -1)
                                                ? <a href={user[key]} target='_blank'>
                                                    <span>{user[key]}</span>
                                                </a>
                                                : <span>{user[key]}</span>
                                            : typeof user[key] === 'object' && !Array.isArray(user[key]) && user[key] !== null
                                                ? ['lastLocation'].includes(key)
                                                    ? createLastLocationElement(user[key])
                                                    : ['Onboarding Driver Address'].includes(key)
                                                        ? createAddressElement(user[key])
                                                        : user[key].hasOwnProperty('seconds') && user[key].hasOwnProperty('nanoseconds')
                                                            ? <span>{user[key].toDate().toDateString()}</span>
                                                            : <span>{JSON.stringify(user[key])}</span>
                                                : <span>Error: user[key] is neighter Array, nor string or object</span>
                                    : <span>Error: user or key in user does not exist</span>}
                        </p>
                    </div>
                </div>)
            }
            <h3 className="ml-10 mt-10 text-xl">Cars</h3>
            {
                cars && cars.map(car => <div>
                    <h4 className="ml-10 mt-10 text-lg">Car {car.id}</h4>
                    {Object.keys(car).map(key => <div className={classes.requestViewContainer}>
                        <div className={classes.requestView}>
                            <h4 className={classes.request}>
                                {toTitleCase(key.replace(/_/g, ' '))}
                            </h4>
                            <p className={classes.request}>
                                {
                                    car.hasOwnProperty(key) && car[key]
                                        ? Array.isArray(car[key])
                                            ? car[key].map(value => <>
                                                {value && (value.indexOf('http://') !== -1 || value.indexOf('https://') !== -1)
                                                    ? <a href={value} target='_blank'>
                                                        <span>{value}</span>
                                                    </a>
                                                    : <span>{value}</span>}
                                            </>)
                                            : typeof car[key] === 'string'
                                                ? car[key].hasOwnProperty('indexOf') && (car[key].indexOf('http://') !== -1 || car[key].indexOf('https://') !== -1)
                                                    ? <a href={car[key]} target='_blank'>
                                                        <span>{car[key]}</span>
                                                    </a>
                                                    : <span>{car[key]}</span>
                                                : typeof car[key] === 'object' && !Array.isArray(car[key]) && car[key] !== null
                                                    ? ['Location'].includes(key)
                                                        ? createLastLocationElement(car[key])
                                                        : ['Address'].includes(key)
                                                            ? createAddressElement(car[key])
                                                            : car[key].hasOwnProperty('seconds') && car[key].hasOwnProperty('nanoseconds')
                                                                ? <span>{car[key].toDate().toDateString()}</span>
                                                                : <span>{JSON.stringify(car[key])}</span>
                                                    : <span>Error: car[key] is neighter Array, nor string or object</span>
                                        : <span>Error: car or key in car does not exist</span>}
                            </p>
                        </div>
                    </div>)}
                </div>)
            }
            <div className={classes.requestViewContainer}>
                <h4>
                    Credit Card Insurance
                </h4>
                <div className={classes.requestView}>
                    <h4 className={classes.request}>
                        Status
                    </h4>
                    <p className={classes.request}>
                        {user?.insuranceStatus}
                    </p>
                    <div className={classes.requestBtnContainer}>
                        {user?.insuranceStatus === 'Not Provided' ?
                            <>
                            </>
                            :
                            <>
                                <button onClick={() => openInNewTab(user?.insuranceURL)} className={classes.requestBtn}>
                                    View Document
                                </button>
                                <button onClick={() => verifyCreditCard(user?.insuranceStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification')} className={classes.requestBtn}>
                                    {user?.insuranceStatus !== 'Verified' ? 'Verify License' : 'Cancel Verification'}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Driver;