import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-select-dt';

import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';

const useStyles = makeStyles({
    container: {
        margin: '5px',
        marginTop: '100px',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10px',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 600px)": {
            marginTop: '2rem',
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    lastActiveValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '0'
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',

    },
    avatarValue: {
        "@media (max-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
            marginTop: '0'
        },
    },
    accessBtn: {
        fontWeight: 'bold',
        color: '#042940',
        "@media (min-width: 992px)": {
            width: '21.6%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    }
});

function User() {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');
    const [pageToken, setPageToken] = useState(null);
    const [table, setTable] = useState(null);

    useEffect(() => {
        setLoading(true);

        (async () => {
            try {
                let result;

                // firebase.functions().useEmulator("127.0.0.1", 5001);
                const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

                if (pageToken) {
                    result = await getInactiveUsers({
                        nextPageToken: pageToken
                    });
                } else {
                    result = await getInactiveUsers({});
                }

                const { data } = result;

                setUsers(data.users);

                setPageToken(data.pageToken);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })()
    }, [update]);

    useEffect(() => {
        if (users && users.length > 0) {
            if (!table) {
                const _table = new DataTable('#users-list-table', {
                    responsive: true,
                    select: true,
                    order: [[3, 'desc']],
                    dom: 'Bfrtip', // Define where buttons will be placed
                    buttons: [
                        {
                            text: 'Send Push Notification (Selected)',
                            action: function (e, dt, node, config) {
                                if (e) {
                                    e.preventDefault();
                                }
                                var selectedRowsData = _table.rows({ selected: true }).data();
                                processSelectedRows(selectedRowsData);
                            }
                        },
                        {
                            text: 'Send Push Notification (All)',
                            action: function (e, dt, node, config) {
                                if (e) {
                                    e.preventDefault();
                                }
                                var allRowsData = _table.rows().data();
                                processAllRows(allRowsData);
                            }
                        }
                    ]
                });
                setTable(_table);
            }
        }
    }, [users]);

    const processSelectedRows = async (selectedRowsData) => {
        // Handle your logic for selected rows
        var userIds = [];
        selectedRowsData.each(function (rowData) {
            var t = document.createRange().createContextualFragment(rowData[1]);
            var userId = t.firstElementChild.getAttribute('href').replace('/user/', '');
            userIds.push(userId);
        });
        for (const userId of userIds) {
            const user = await firebase.firestore().collection('users').doc(userId).get();
            const { token, device } = user.data();
            const title = prompt('Type title');
            const message = prompt('Type message');
            if (title && message) {
                await sendNotification({ title, subtitle: '', message, token, device });
            }
        }
    };

    const processAllRows = (allRowsData) => {
        // Handle your logic for all rows
        var driverIds = [];
        allRowsData.each(function (rowData) {
            var t = document.createRange().createContextualFragment(rowData[1]);
            var driverId = t.firstElementChild.getAttribute('href').replace('/driver/', '');
            driverIds.push(driverId);
        });
        console.log(driverIds.join('\n'));
    };

    const sendNotification = async ({ title, subtitle, message, token, device }) => {
        if (!process.env.REACT_APP_RYDE_API) {
            console.error('process.env.REACT_APP_RYDE_API env var is undefined');
            return;
        }

        if (token) {
            let url;
            let data;

            if (device === 'android') {
                console.log('Preparing for android push');
                url = process.env.REACT_APP_RYDE_API + '/notify/android';
                data = {
                    "message": {
                        "token": token,
                        "notification": {
                            title,
                            body: message,
                        },
                        "android": {
                            priority: "high",
                            notification: {
                            },
                        },
                        "data": {
                            name: "RIDE",
                            riderId: "abcd12345",
                        },
                    }
                };
            } else {
                console.log('Preparing for iphone push');
                url = process.env.REACT_APP_RYDE_API + '/notify/ios';
                data = {
                    token,
                    device,
                    aps: {
                        category: 'RIDE',
                        alert: {
                            title,
                            subtitle,
                            body: message,
                        },
                        sound: 'default',
                    },
                    body: {
                        name: 'RIDE',
                    }, // equivalent of "data": {} in android
                    experienceId: '@riamuapp/riamu',
                    scopeKey: '@riamuapp/riamu',
                };
            }

            const currentUser = firebase.auth().currentUser;
            const idToken = await currentUser.getIdToken(true);

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(data),
            };

            console.log(`HTTP POST ${url}`, data);

            fetch(url, config)
                .then(function (response) {
                    console.log('response', response);
                })
                .catch(function (error) {
                    console.log('error', error);
                });
        }
    };

    const filterData = async (value) => {
        try {
            let result;
            let inactiveUsers = [];

            const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

            if (pageToken) {
                result = await getInactiveUsers({
                    nextPageToken: pageToken
                });
            } else {
                result = await getInactiveUsers({});
            }

            const { data } = result;

            if (value === '30 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 30 * 24 * 60 * 60 * 1000));
            } else if (value === '60 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 60 * 24 * 60 * 60 * 1000));
            } else {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 90 * 24 * 60 * 60 * 1000));
            }

            setUsers(inactiveUsers);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    }

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return;
            }

            setLoading(true);

            setPageToken(null);

            await filterData(event.target.value);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

    return <div className={classes.container}>
        <table id='users-list-table' style={{ width: '100%' }}>
            <thead>
                <tr>
                    <td>
                        Avatar
                    </td>
                    <td>
                        User name
                    </td>
                    <td>
                        Email
                    </td>
                    <td>
                        Last Active
                    </td>
                </tr>
            </thead>
            {loading ?
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <div className={classes.noRequestFound}>
                                <CircularProgress color="secondary" />
                            </div>
                        </td>
                    </tr>
                </tbody>
                : !users || users.length === 0 ?
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                No User Found.
                            </td>
                        </tr>
                    </tbody>
                    : <tbody>
                        {
                            users && users.map((item, i) =>
                                <tr key={i} >
                                    <td>
                                        <Avatar
                                            alt='logo'
                                            src={item.photoURL}
                                            sx={{
                                                width: 45,
                                                height: 45,
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/user/${item.uid}`} className={classes.username}>
                                            {item?.displayName}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={'mailto:' + item.email}>{item.email}</Link>
                                    </td>
                                    <td data-sort={Date.parse(item.metadata?.lastSignInTime)}>
                                        {item.metadata?.lastSignInTime}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
            }
        </table>
    </div>;
}

export default User