import React, { useEffect, useState } from 'react'
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import Auth from './pages/Auth';
import Home from './pages/Home';
import User from './pages/User';
import Driver from './pages/Driver';

function App() {
  const navigate = useNavigate();
  const [loadingInitial, setLoadingInitial] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          console.log('idTokenResult.claims.admin', idTokenResult.claims.admin);
          if (!!idTokenResult.claims.admin) {
            navigate('/'); // Home
          } else {
            console.log('Not authorized. Singing out.');
            firebase.auth().signOut();
          }
        });
      } else {
        navigate('/login'); // Auth
      }
      setLoadingInitial(false);
    });

    return () => { };
  }, []);

  if (loadingInitial) {
    return;
  }

  return (
    <Routes>
      <Route path="login" element={<Auth />} />
      <Route path="/" index element={<Home />} />
      <Route path="/user/:userId" element={<User />} />
      <Route path="/driver/:userId" element={<Driver />} />
    </Routes>
  );
}

export default App;